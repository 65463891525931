





















































































































































































































































































































































































































































































































import {
    copySectionApi,
    copySectionMessageApi,
    deleteSectionMessageApi,
    getConversationMessagesApi,
    getConversationRespondentsApi,
    getConversationsApi,
    getOnboardingPollsApi,
    getProjectApi,
    moveSectionApi,
    moveSectionMessageApi,
    updateOrCreateConversationMessageApi,
    updateOrCreateSectionApi,
    updateOrCreateSectionMessageApi,
    changeSectionMessageStatusApi,
    startProjectApi,
    moveOnboardingPollApi,
    copyOnboardingPollApi,
    deleteOnboardingPollApi,
    getConversationSingleMessagesApi,
    stopTestGroupApi,
    stopCurrentTestGroupApi,
    testGroupsApi,
    deleteTestGroupApi,
    getProjectSettingsApi,
    setProjectSettingsApi
} from '@/api/project'
import { showError } from '@/utils/common'
import { computed, defineComponent, nextTick, onBeforeUnmount, onMounted, onUnmounted, provide, reactive, Ref, ref, set, toRef, unref, watch } from '@vue/composition-api'
import { Alert, Checkbox, Switch } from 'element-ui'
import ShowFormWrap from '@/components/form-common-show/ShowFormWrap.vue'
import Draggable from 'vuedraggable'
import ProjectWordCloudDialog from './components/ProjectWordCloudDialog.vue'
import ProjectAIAnalyzeDialog from './components/ProjectAIAnalyzeDialog.vue'
import ProjectItemTestGroupsDialog from './components/ProjectItemTestGroupsDialog.vue'
import VirtualScrollList from 'vue-virtual-scroll-list';
import {
    ConversationStatusType,
    EntityType,
    EntityTypeOption,
    ProjectFormType,
    ProjectStatus,
    testGroupStatus,
    UpdateOrCreateSectionDto,
    UpdateOrCreateSectionMessageDto
} from '@/api/project/model'
import { ARTClient, ARTEvent, ARTNotifyEventType, ConversationBody, LoginGuard, MessageBody, SendMessageBody } from '@/services/sdk'
import ProjectLiveTalk from './components/ProjectLiveTalk.vue'
import ProjectLiveSend from './components/ProjectLiveSend.vue'
import { useScrollTo } from '@/hooks/useScrollTo'
import { useSetInterval } from '@/hooks/useSetInterval'
import ProjectClosePopover from './components/ProjectClosePopover.vue'
import ProjectSharePopover from './components/ProjectSharePopover.vue'
import ProjectGroupDrawer from './components/projectGroup/ProjectGroupDrawer.vue'
import InputAlter from '@/components/InputAlter.vue'
import ProjectSectionSort from './components/ProjectSectionSort.vue'
import ProjectItemMove from './components/ProjectItemMove.vue'
import ProjectTalkLoading from './components/ProjectTalkLoading.vue'
import ProjectPublished from './components/ProjectPublished.vue'
import FormBasicDialogWrap from '@/components/form-common-edit-dialog/FormBasicDialogWrap.vue'
import FormSessionDialogWrap from '@/components/form-common-edit-dialog/FormSessionDialogWrap.vue'
import { useUserStore } from '@/pinia/modules/user'
import ProjectSurveyError from './components/ProjectSurveyError.vue'
import { useProjectStore } from '@/pinia/modules/project'
import ProjectCountdown from './components/ProjectCountdown.vue'
import ProjectRespondents from './components/ProjectRespondents.vue'
import CountTo from 'vue-count-to'
import { storeToRefs } from 'pinia'
import { verifyMessageEntity } from '@/utils/verify-message-entity'
import TitleTooltip from '@/components/TitleTooltip.vue'
import { entityTypeTemplate, handleNlpSettings } from '@/api/project/entity-type-template'
import ProjectDataDerive from './components/ProjectDataDerive.vue'
import _ from 'lodash'
import { timeFormat } from '@/utils/time-format'
import workerTimer from '@/utils/worker-timer'
export default defineComponent({
    components: {
        [Switch.name]: Switch,
        [Checkbox.name]: Checkbox,
        [Alert.name]: Alert,
        ShowFormWrap,
        ProjectLiveSend,
        ProjectClosePopover,
        ProjectSharePopover,
        ProjectGroupDrawer,
        InputAlter,
        ProjectSectionSort,
        ProjectItemMove,
        ProjectTalkLoading,
        ProjectPublished,
        FormBasicDialogWrap,
        FormSessionDialogWrap,
        Draggable,
        ProjectSurveyError,
        ProjectCountdown,
        ProjectRespondents,
        CountTo,
        TitleTooltip,
        ProjectDataDerive,
        ProjectWordCloudDialog,
        ProjectAIAnalyzeDialog,
        ProjectItemTestGroupsDialog,
        VirtualScrollList
    },
    setup(props, { root, refs }) {
        const projectUuId = computed(() => root.$route.params.projectUuId)
        const { timerStart, timerClear } = useSetInterval()
        const userStore = useUserStore()
        const projectStore = useProjectStore()
        const { projectInfo } = storeToRefs(projectStore)

        const dataDriver = computed(() => projectInfo.value.dataDriver ?? '')
        const head = computed(() => projectInfo.value.detail_moderator_cover || root.$defaultAvatar)


        provide('head', head)
        provide('driver', dataDriver)

        const handleProjectTotalTime = () => {
            if (projectInfo.value.started_at && projectInfo.value.ended_at) {
                const t = new Date(projectInfo.value.ended_at).getTime() - new Date(projectInfo.value.started_at).getTime()
                return t > 0 ? timeFormat(t) : '00:00:00'
            }
            return '00:00:00'
        }


        const dataDeriveInfo = reactive({
            show: false
        })


        const scrollTalkRef = ref()
        const scrollTalkInfo = reactive({
            scrollTo: async (to: number, duration = 500) => {
                nextTick(() => {
                    const scrollbar = unref(scrollTalkRef)
                    if (!scrollbar) {
                        return
                    }
                    const { start } = useScrollTo({
                        el:  document.getElementById('talkScroll'),
                        to,
                        duration
                    })
                    start()
                })
            },
            isBottom: () => {
                
                nextTick(() => {

                    return scrollTalkRef.value.scrollHeight - scrollTalkRef.value.scrollTop === scrollTalkRef.value.clientHeight
                })
            },
            scrollBottom: () => {
                nextTick(() => {
                    // const scrollbar = unref(scrollTalkRef)
                    scrollTalkRef.value.scrollToBottom()
                    
                    if (scrollTalkRef) {
                        // const scrollHeight = scrollbar.scrollHeight
                        // setTimeout(() => {
                            // scrollTalkRef.value.scrollToIndex(liveConversationsTotal.value - 1)
                        // },1)
                        // scrollTalkInfo.scrollTo(scrollHeight, 500)
                    }
                })
            },
            scrollLocation: (item: any) => {
                const location = document.getElementById(`talk-${item.message_entity_id}`) as any
                if (location) {
                    const { offsetTop, offsetParent } = location
                    scrollTalkInfo.scrollTo(offsetTop - 80)
                    const className = location.getElementsByClassName('wrap-f-r')[0]
                    className.classList.add('talk-bg-color-animation')
                    setTimeout(() => className.classList.remove('talk-bg-color-animation'), 1000)
                }
            },
            scrollLocationPoll: (item: any) => {
                let index=0
                formattedConversationList.value.map((items:any,indexs:any)=>{
                    if(items.message.message_entity_id==item.message_entity_id){
                        index=indexs
                    }
                })
                if(scrollTalkRef){
                    scrollTalkRef.value.scrollToIndex(index)
                }
            }
        })

       

        const formattedConversationList = computed(() => {
            if (!liveObj) {
                return [];
            }

            const pollList = liveObj.pollList?.map((item: any) => ({
                ...item,
                id: item.message.message_identifier, // 提取 message.id 并添加到每个对象中
            })) || [];

            const conversationList = liveObj.conversationList?.map((item: any) => ({
                ...item,
                id: item.message.message_identifier, // 提取 message.id 并添加到每个对象中
            })) || [];

            return [...pollList, ...conversationList];
        });
        const scrollFormRef = ref()
        const scrollFormInfo = reactive({
            scrollTo: async (to: number, duration = 500) => {
                nextTick(() => {
                    const scrollbar = unref(scrollFormRef)
                    if (!scrollbar) {
                        return
                    }
                    const { start } = useScrollTo({
                        el: scrollbar,
                        to,
                        duration
                    })
                    start()
                })
            },
            next: () => {
                const { sections } = conversationObj
                let nextItem: any
                for (let index = 0; index < sections.length; index++) {
                    const element = sections[index]
                    nextItem = element.items.find((v: any) => v.status === ConversationStatusType.PENDING)
                    if (nextItem) {
                        element.isCollapse = true
                        break
                    }
                }
                if (nextItem) {
                    nextTick(() => {
                        const location = document.getElementById(`form-${nextItem.id}`)
                        if (location) {
                            const { offsetTop, offsetParent } = location as any
                            if (offsetTop && offsetParent?.offsetTop) {
                                scrollFormInfo.scrollTo(offsetParent.offsetTop + offsetTop)
                                location.classList.add('form-bg-color-animation')
                                setTimeout(() => location.classList.remove('form-bg-color-animation'), 2000)
                            }
                        }
                    })
                }
            }
        })


        const projectStatusInfo = reactive({
            submitLoad: false,
            onStart: async () => {
                projectStatusInfo.submitLoad = true
                try {
                    await startProjectApi({
                        uuid: projectUuId.value
                    })

                } catch (error) {
                    showError(error)
                }
                projectStatusInfo.submitLoad = false
            },
            onPublished: () => {
                console.log(`onPublished 发布成功`)

            },
            onEnd: () => {
                console.log(`onEnd 项目结束`)

            }
        })


        const outlineObj = reactive({
            isCollapse: true,
            isAutoSend: computed(() => {
                return projectInfo.value.isAutoSend
            }),
            autoSendUsed: false,
            confirmButtonLoading: false,
            setProjectSettings: async (isSend: boolean) => {
                try {
                    const data = await setProjectSettingsApi({
                        uuid: projectUuId.value,
                        auto_send: isSend ? isSend : projectInfo.value.isAutoSend
                    })
                    outlineObj.confirmButtonLoading = false
                    outlineObj.autoSendUsed = false
                    projectInfo.value.autoSendUsed = data.auto_send_used
                    projectInfo.value.isAutoSend = data.auto_send
                } catch (error) {
                    outlineObj.confirmButtonLoading = false
                    outlineObj.autoSendUsed = false
                    showError(error)
                }
            },
            handleAutoSendChange() {
                console.log(projectInfo.value.autoSendUsed)
                if (!projectInfo.value.autoSendUsed) {
                    outlineObj.autoSendUsed = true
                    projectInfo.value.isAutoSend = false
                } else {
                    outlineObj.setProjectSettings(false)
                }
            },
            onAutoSendUsed() {
                outlineObj.confirmButtonLoading = true
                outlineObj.setProjectSettings(true)
            }
        })


        const baseObj: any = reactive({
            isCollapse: true,
            id: 0,
            polls: [] as any[],
            onCopy: async (item: any, index: number) => {
                try {
                    const data = await copyOnboardingPollApi({
                        uuid: projectUuId.value,
                        id: item.id
                    })
                    baseObj.polls.insert(index + 1, data)
                    await baseObj.onMove()
                    root.$message.success(<string>root.$i18n.t('copySuccess'))
                } catch (error) {
                    showError(error)
                }
            },
            onMove: async () => {
                const sortData: any = {}
                baseObj.polls.forEach((item: any, index: number) => {
                    sortData[item.id] = index
                })
                try {
                    await moveOnboardingPollApi({
                        uuid: projectUuId.value,
                        data: sortData
                    })
                    baseObj.polls.forEach((item: any, index: number) => {
                        item.sequence = index
                    })
                } catch (error) {

                }
            },
            allowPulish: computed(() => {
                return !baseObj.polls.some((poll: any) => verifyMessageEntity(poll))
            }),
            className: (itemObj: any) => {
                let is_tag=itemObj.message_entity.is_tag
                if ([ProjectStatus.Start, ProjectStatus.Published, ProjectStatus.End].includes(projectInfo.value.status)||is_tag) {
                    return 'disabled'
                } else {
                    return 'active'
                }
            },
            handleToolbar: (itemObj: any) => {
                let tools: string[] = []

                const isSend = liveObj.pollList.some((v: any) => v.message.message_entity_id == itemObj.message_entity_id)
                if (isSend) {
                    tools.push('查看该内容')
                }
                if (projectInfo.value.status !== ProjectStatus.End) {
                    let is_tag=itemObj.message_entity.is_tag
                    if (is_tag) {
                        tools.push('删除')
                        if (
                        !verifyMessageEntity(itemObj) &&
                        [EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(itemObj.message_entity.entity_type) &&

                        conversationObj.sections.length > 0
                    ) {
                        tools.push('分支追问')
                    }
                    }else{
                        if (!isSend) {
                        tools.push('编辑', '添加', '复制', '删除')
                    }

                    if (
                        !verifyMessageEntity(itemObj) &&
                        [EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(itemObj.message_entity.entity_type) &&

                        conversationObj.sections.length > 0
                    ) {
                        tools.push('分支追问')
                    }
                    }
                   
                }
                return tools
            },
            onDelete: async (item: any, index: number) => {
                let message = <string>root.$i18n.t('projectLive.deleteItem.deleteMsg1')
                const branchCount = conversationObj.hasBranchIds.filter((j: number) => j == item.message_entity_id).length
                if (branchCount > 0) {
                    message = <string>root.$i18n.t('projectLive.deleteItem.deleteMsg2', { branchCount })
                }

                root.$msgbox({
                    showCancelButton: true,
                    dangerouslyUseHTMLString: true,
                    message,
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            try {
                                instance.confirmButtonLoading = true
                                if (branchCount > 0) {
                                    await deleteBranch([item.message_entity_id])
                                }
                                await deleteOnboardingPollApi({
                                    uuid: projectUuId.value,
                                    id: item.id
                                })
                                baseObj.polls.splice(index, 1)
                                await baseObj.onMove()
                                root.$message.success(root.$t('deleteSuccess').toString())
                                done()
                                instance.confirmButtonLoading = false
                            } catch (error) {
                                showError(error)
                            }
                        } else {
                            done()
                        }
                    }
                }).catch(() => { })
            }
        })
        provide('baseObj', baseObj)


        const getOnboardingPolls = async () => {
            try {
                const { id, polls } = await getOnboardingPollsApi({
                    uuid: projectUuId.value
                })
                baseObj.id = id
                baseObj.polls = _.cloneDeep(polls)
            } catch (error) {
                showError(error)
            }
        }


        const getConversationsReal = async () => {
            if (!projectUuId.value) return
            try {
                const { id, sections } = await getConversationsApi({
                    uuid: projectUuId.value
                })
                conversationObj.id = id
                sections.map((item: any) => {
                    const oldItem = conversationObj.sections.find((v) => v.id == item.id)
                    item.isCollapse = oldItem ? oldItem.isCollapse : true
                })
                conversationObj.sections = sections
                statusTypeObj.refreshConversationList()
            } catch (error) {
                showError(error)
            }
        }

        const getConversations = _.debounce(getConversationsReal, 500)


        const conversationObj = reactive({
            isCollapse: true,
            id: 0,
            isDeleteMaterial: false,
            isDeleteTestGruop: false,
            nowTestGruopName: '',
            confirmButtonLoading: false,
            totalNum: computed(() => {
                const { sections } = conversationObj
                let total = 0
                sections.forEach((section: any) => {
                    total += section.items.length
                })
                return total
            }),
            testGroups: [] as any[],
            hasBranchIds: computed(() => {
                const { sections } = conversationObj
                const hasBranchIds: any[] = []
                sections.forEach((section: any) => {
                    section.items.forEach((item: any) => {
                        if (item.message_entity?.parent_id) {
                            hasBranchIds.push(item.message_entity.parent_id)
                        }
                    })
                })
                return hasBranchIds
            }),
            sections: [] as any[],
            sectionList: [] as any[],
            getParentItem: (parentId: number) => {
                const { polls } = baseObj
                const { sections } = conversationObj
                let parentItem

                polls.forEach((poll: any) => {
                    if (poll.message_entity.id === parentId) {
                        parentItem = poll
                    }
                })

                sections.forEach((section: any) => {
                    section.items.forEach((item: any) => {
                        if (item.message_entity?.id === parentId) {
                            parentItem = item
                        }
                    })
                })
                return parentItem
            }
        })
        provide('conversationObj', conversationObj)


        const sectionObj = reactive({
            onUpdate: async (item: any, index: number, name?: string, color?: string) => {
                // console.log(item, index, name, color)
                try {
                    const option: UpdateOrCreateSectionDto = {
                        uuid: projectUuId.value,
                        section_name: name || item.section_name,
                        color: color || item.color,
                        guide_id: conversationObj.id,
                        id: item.id,
                        sequence: item.sequence
                    }
                    const data = await updateOrCreateSectionApi(option)
                    Object.keys(conversationObj.sections[index]).forEach((key) => {
                        if (!['items', 'isCollapse'].includes(key)) {
                            conversationObj.sections[index][key] = data[key]
                        }
                    })
                } catch (error) {
                    showError(error)
                }
            },
            onNameEdit: (item: any) => {
                ; (refs[`inputAlterRef-${item.id}`] as any)[0].onClick()
                    ; (refs[`sectionPopoverRef-${item.id}`] as any)[0].doClose()
            },
            onNameChange: async (name: string, item: any, index: number) => sectionObj.onUpdate(item, index, name),
            onCopy: async (item: any, index: number) => {
                try {
                    const data = await copySectionApi({
                        uuid: projectUuId.value,
                        sectionId: item.id
                    })
                    data.isCollapse = true
                    conversationObj.sections.insert(index + 1, data)
                    await sectionObj.onMove()
                    root.$message.success(root.$t('copySuccess').toString())
                        ; (refs[`sectionPopoverRef-${item.id}`] as any)[0].doClose()
                } catch (error) {
                    showError(error)
                }
            },
            onMove: async () => {
                const sortData: any = {}
                conversationObj.sections.forEach((item, index) => {
                    sortData[item.id] = index
                })
                try {
                    await moveSectionApi({
                        uuid: projectUuId.value,
                        data: sortData
                    })
                    conversationObj.sections.forEach((item, index) => {
                        item.sequence = index
                    })
                } catch (error) {
                    showError(error)
                }
            },
            onTestGroups: async () => {
                try {
                    const data = await testGroupsApi({
                        uuid: projectUuId.value,
                    })
                    conversationObj.testGroups = data.filter((v: any) => v.status == "ready")
                    conversationObj.testGroups = data
                } catch (error) {

                }
            }
        })


        const messageObj = reactive({
            nowMessageObj: {
                obj: {} as any,
                i: 0 as number,
                item: {} as any,
                index: 0 as number,
                test_group_id: 0 as number
            },
            allowSend: (item: any) => {
                if (projectInfo.value.status == ProjectStatus.Start) {
                    let testGroupId = item.message_entity.test_group_id
                    if (testGroupId != 0) {
                        let testGroup = projectInfo.value.test_group
                        let testGroupInfo = projectInfo.value.test_group_info
                        let entityType = item.message_entity.entity_type
                        if (testGroup == null) {
                            if (liveObj.timeInfo.isCollecting) {
                                return false
                            }
                            if (verifyMessageEntity(item)) {
                                return false
                            }
                            if (item.message_entity.test_group && item.message_entity.test_group.status == testGroupStatus.Done) {
                                return false
                            }
                            if (entityType == EntityType.image || entityType == EntityType.video) {
                                if (sendObj.sendingId) {
                                    return false
                                }
                                if (verifyMessageEntity(item)) {
                                    return false
                                }
                                return item.status == ConversationStatusType.PENDING
                            } else {
                                return false
                            }
                        }
                        if (testGroup != null) {
                            if (testGroupId == testGroup.id) {
                                if (liveObj.timeInfo.isCollecting) {
                                    return false
                                }
                                if (verifyMessageEntity(item)) {
                                    return false
                                }

                                if (testGroupInfo.status == testGroupStatus.Done) {
                                    return false
                                }
                                const test_group_rounds: any = []
                                testGroupInfo.message_entities.map((message: any) => {
                                    message.test_group_rounds.map((round: any) => {
                                        if (testGroupInfo.current_round == round.round) {
                                            test_group_rounds.push(round)
                                        }
                                    })
                                })
                                let rounds = test_group_rounds.filter((rounds: any) => rounds.status == testGroupStatus.Ready)


                                if (entityType === EntityType.image || entityType === EntityType.video) {
                                    if (rounds.length == 0) {
                                        return true
                                    } else {
                                        return false
                                    }

                                } else {
                                    let nowRound = test_group_rounds.map((round: any) => {
                                        if (item.message_entity_id == round.message_entity_id) {
                                            return round.status
                                        }
                                    })
                                    if (item.message_entity.entity_type === EntityType.branch) {
                                        const parentItem: any = conversationObj.getParentItem(item.message_entity.parent_id)
                                        if (!parentItem) {
                                            return false
                                        } else if (parentItem.section_id) {
                                            let parent_rounds = parentItem.message_entity.test_group_rounds
                                            if (parent_rounds.length > 0) {
                                                return parent_rounds[parent_rounds.length - 1].status == testGroupStatus.Sent && nowRound.includes(testGroupStatus.Ready)
                                            } else {
                                                return false
                                            }
                                        }
                                    }

                                    return nowRound.includes(testGroupStatus.Ready)

                                }

                            } else {

                                return false
                            }

                        }
                    } else {
                        if (sendObj.sendingId) {
                            return false
                        }
                        if (liveObj.timeInfo.isCollecting) {
                            return false
                        }
                        if (verifyMessageEntity(item)) {
                            return false
                        }
                        if (item.message_entity.entity_type === EntityType.branch) {
                            const parentItem: any = conversationObj.getParentItem(item.message_entity.parent_id)
                            if (!parentItem) {
                                return false
                            } else if (parentItem.section_id) {
                                return parentItem.status == ConversationStatusType.SENDED
                            }
                        }
                        return item.status == ConversationStatusType.PENDING
                    }

                } else {
                    return false
                }
            },
            isTestGroupEnd: (item: any) => {
                let test_group = item.message_entity.test_group
                if (test_group && test_group.status == testGroupStatus.Done) {
                    return 'disabled'
                }
            },
            isTestGroupStart: (item: any) => {
                let test_group_rounds = item.message_entity.test_group_rounds
                if (test_group_rounds != null && test_group_rounds.length > 0) {
                    return false
                } else {
                    return true
                }
            },
            allowStop: (item: any) => {
                let testGroupInfo = projectInfo.value.test_group_info
                let testGroupId = item.message_entity.test_group_id
                if (testGroupInfo != null && testGroupId != 0 && testGroupId == testGroupInfo.id && !liveObj.timeInfo.isCollecting) {
                    const test_group_rounds: any = []
                    testGroupInfo.message_entities.map((item: any) => {
                        item.test_group_rounds.map((round: any) => {
                            if (testGroupInfo.current_round == round.round) {
                                test_group_rounds.push(round.status)
                            }
                        })
                    })
                    return test_group_rounds.includes(testGroupStatus.Ready)
                } else {
                    return false
                }
            },
            onCreate: async (val: EntityTypeOption, obj: any, index?: number) => {
                const option: UpdateOrCreateSectionMessageDto = {
                    content: '',
                    sectionId: obj.id,
                    duration: val.duration || 15,
                    entity_type: val.value,
                    guide_id: baseObj.id,
                    uuid: projectUuId.value,
                    link: '',
                    sequence: index! >= 0 ? index! + 1 : obj.items.length
                }
                try {
                    const data = await updateOrCreateSectionMessageApi(option)
                    if (index! >= 0) {
                        obj.items.insert(index! + 1, data)
                        await messageObj.onMove(obj)
                    } else {
                        obj.items.push(data)
                    }
                } catch (error) {
                    showError(error)
                }
            },
            onMove: async (obj: any) => {
                const { items, id } = obj
                const sortData: any = {}
                items.forEach((item: any, index: number) => {
                    sortData[item.id] = index
                })
                try {
                    await moveSectionMessageApi({
                        uuid: projectUuId.value,
                        sectionId: id,
                        data: sortData
                    })
                    obj.items.forEach((item: any, index: number) => {
                        item.sequence = index
                    })
                } catch (error) {

                }
            },
            onCopy: async (obj: any, item: any) => {
                try {
                    const data = await copySectionMessageApi({
                        uuid: projectUuId.value,
                        sectionId: obj.id,
                        messageId: item.id
                    })
                    const section = conversationObj.sections.find((v) => v.id == obj.id)
                    const itemsIndex = section.items.findIndex((v: any) => v.id === item.id)
                    section.items.insert(itemsIndex + 1, data)
                    await messageObj.onMove(obj)
                    root.$message.success(root.$t('copySuccess').toString())
                    statusTypeObj.refreshConversationList()
                } catch (error) {
                    showError(error)
                }
            },
            isDisabled: computed(() => [ProjectStatus.End].includes(projectInfo.value.status)),
            className: (itemObj: any) => {
                
                if (ProjectStatus.End === projectInfo.value.status) {
                    return 'disabled'
                } else if ([ProjectStatus.Published, ProjectStatus.Start].includes(projectInfo.value.status)) {
                    if (liveObj.timeInfo.isCollecting) {
                        if (
                            liveObj.lastMessageEntity &&
                            liveObj.lastMessageEntity.message_entity_id == itemObj.message_entity.id
                        ) {
                            return 'collect'
                        }
                    }

                    if (itemObj.status === ConversationStatusType.SENDED) {
                        return 'disabled'
                    } else {
                        return 'active'
                    }
                } else {
                    return 'active'
                }
            },
            handleToolbar: (itemObj: any) => {
                let tools: string[] = []

                const isSend = liveObj.conversationList.some((v: any) => v.message.message_entity_id == itemObj.message_entity_id)
                if (isSend) {
                    tools.push('查看该内容')
                }

                if (projectInfo.value.status !== ProjectStatus.End) {
                    if (projectInfo.value.status != ProjectStatus.Start || !projectInfo.value.isAutoSend) {
                        tools.push('添加')
                        if (itemObj.message_entity.entity_type != EntityType.branch) {
                            tools.push('复制')
                        }
                        if ([ConversationStatusType.PENDING, ConversationStatusType.SKIPED].includes(itemObj.status)) {
                            tools.push('移动', '删除')
                            if (![EntityType.speak, EntityType.video, EntityType.image, EntityType.upload_image, EntityType.branch].includes(itemObj.message_entity.entity_type)) {
                                tools.push('加入测试组')
                            }
                            if (itemObj.message_entity.entity_type == EntityType.branch) {
                                tools.push('分支编辑')
                            } else {
                                tools.push('编辑')
                            }
                        }

                        if (
                            !verifyMessageEntity(itemObj) &&
                            [EntityType.poll, EntityType.picture_poll].includes(itemObj.message_entity.entity_type) &&

                            conversationObj.sections.length > 0
                        ) {
                            tools.push('分支追问')
                        }
                    }

                    if (projectInfo.value.status == ProjectStatus.Start && !projectInfo.value.isAutoSend) {
                        if (itemObj.status == ConversationStatusType.PENDING) {
                            tools.push('跳过')
                        }
                        if (itemObj.status == ConversationStatusType.SKIPED) {
                            tools.push('恢复')
                        }
                    }


                }
                if ([EntityType.ask_opinion, EntityType.branch].includes(itemObj.message_entity.entity_type)) {
                    tools.push('词云')
                }
                if ([EntityType.upload_image].includes(itemObj.message_entity.entity_type)) {
                    tools.push('AI分析')
                }

                return tools
            },
            onDelete: async (obj: any, i: number, item: any, index: number) => {
                let message = <string>root.$i18n.t('projectLive.deleteItem.deleteMsg1')
                const branchCount = conversationObj.hasBranchIds.filter((j: number) => j == item.message_entity_id).length
                const formType = item.message_entity.entity_type
                if (branchCount > 0) {
                    message = <string>root.$i18n.t('projectLive.deleteItem.deleteMsg2', { branchCount })
                }
                const sectionName = item.message_entity.test_group ? item.message_entity.test_group.name : ''
                const testGroupId = item.message_entity.test_group_id
                if ((formType == 'image' || formType == 'video') && testGroupId != 0) {
                    conversationObj.nowTestGruopName = <string>root.$i18n.t('projectSurvey.deleteItem.deleteMsg3', { sectionName })
                    messageObj.nowMessageObj.obj = obj
                    messageObj.nowMessageObj.i = i
                    messageObj.nowMessageObj.item = item
                    messageObj.nowMessageObj.index = index
                    messageObj.nowMessageObj.test_group_id = testGroupId
                    conversationObj.isDeleteMaterial = true
                    conversationObj.isDeleteTestGruop = false
                } else {
                    root.$msgbox({
                        showCancelButton: true,
                        dangerouslyUseHTMLString: true,
                        message,
                        beforeClose: async (action, instance, done) => {
                            if (action === 'confirm') {
                                try {
                                    instance.confirmButtonLoading = true
                                    if (branchCount > 0) {
                                        await deleteBranch([item.message_entity_id])
                                    }
                                    await deleteSectionMessageApi({
                                        uuid: projectUuId.value,
                                        sectionId: obj.id,
                                        messageId: item.id
                                    })
                                    conversationObj.sections[i].items.splice(index, 1)
                                    await messageObj.onMove(obj)
                                    root.$message.success(root.$t('deleteSuccess').toString())
                                    done()
                                    instance.confirmButtonLoading = false
                                } catch (error) {
                                    showError(error)
                                }
                            } else {
                                done()
                            }
                        }
                    }).catch(() => { })
                }
            },
            onStopTestGroup: async (id: number) => {
                let message = <string>root.$i18n.t('projectTestGroup.stopTestGroupMsg')
                root.$msgbox({
                    showCancelButton: true,
                    dangerouslyUseHTMLString: true,
                    message,
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            try {
                                instance.confirmButtonLoading = true
                                await stopTestGroupApi({
                                    uuid: projectUuId.value,
                                    testGroupId: id,
                                })
                                getConversations()
                                done()
                                instance.confirmButtonLoading = false
                            } catch (error) {
                                showError(error)
                            }
                        } else {
                            done()
                        }
                    }
                }).catch(() => { })
            },
            onDeleteTestGroup: async () => {
                conversationObj.confirmButtonLoading = true
                try {
                    await deleteSectionMessageApi({
                        uuid: projectUuId.value,
                        sectionId: messageObj.nowMessageObj.obj.id,
                        messageId: messageObj.nowMessageObj.item.id
                    })
                    conversationObj.sections[messageObj.nowMessageObj.i].items.splice(messageObj.nowMessageObj.index, 1)
                    await messageObj.onMove(messageObj.nowMessageObj.obj)
                    if (conversationObj.isDeleteTestGruop) {
                        await deleteTestGroupApi({
                            uuid: projectUuId.value,
                            sectionId: messageObj.nowMessageObj.test_group_id
                        })

                    }

                    root.$message.success(root.$t('deleteSuccess').toString())
                    conversationObj.isDeleteMaterial = false
                    conversationObj.confirmButtonLoading = false
                } catch (error) {
                    showError(error)
                }
            },
        })


        const deleteBranch = async (parentIds: number[]) => {
            const sections = conversationObj.sections
            for (let i = 0; i < sections.length; i++) {
                const obj = sections[i]
                for (let index = obj.items.length - 1; index >= 0; index--) {
                    const item = obj.items[index]
                    if (parentIds.includes(item.message_entity.parent_id)) {
                        await deleteSectionMessageApi({
                            uuid: projectUuId.value,
                            sectionId: obj.id,
                            messageId: item.id
                        })
                        conversationObj.sections[i].items.splice(index, 1)
                        await messageObj.onMove(obj)
                    }
                }
            }
        }


        const projectSectionSortRef: Ref<any> = ref(null)
        const sectionSortInfo = reactive({
            onOpen: () => {
                projectSectionSortRef.value.onOpen(conversationObj.sections)
            },
            onSuccess: () => getConversations()
        })


        const projectItemMoveRef: Ref<any> = ref(null)
        const itemMoveInfo = reactive({
            onOpen: (item: any) => {
                projectItemMoveRef.value.onOpen(item)
            },
            onSuccess: () => {
                getConversations()
            }
        })


        const talkCreateBranch = (itemObj: any) => {
            let itemInfo = conversationObj.getParentItem(itemObj.message_entity_id)
            if (itemInfo) {
                editSessionObj.onOpen(itemInfo, 'create', EntityType.branch)
            }
        }

        provide('createBranch', talkCreateBranch);


        const changeSectionMessageStatusFun = async (item: any) => {
            if (item.status == ConversationStatusType.SENDED) {
                return
            }
            const status = item.status == ConversationStatusType.PENDING ? ConversationStatusType.SKIPED : ConversationStatusType.PENDING
            try {
                await changeSectionMessageStatusApi({
                    uuid: item.project_uuid,
                    sectionId: item.section_id,
                    messageId: item.id,
                    status
                })
                item.status = status
            } catch (error) {
                showError(error)
            }
        }


        const statusTypeObj = reactive({
            statusTypeList: [] as ConversationStatusType[],
            isChecked: (item: any) => {
                return statusTypeObj.statusTypeList.includes(item)
            },
            handleClick: (item: any) => {
                if (statusTypeObj.statusTypeList.includes(item)) {
                    statusTypeObj.statusTypeList = statusTypeObj.statusTypeList.filter((i) => i !== item)
                } else {
                    statusTypeObj.statusTypeList.push(item)
                }
            },
            refreshConversationList: () => {

                if (statusTypeObj.statusTypeList.length === 0) {
                    conversationObj.sectionList = conversationObj.sections
                } else {
                    const sectionList = [] as any[]
                    conversationObj.sections.forEach((section: any) => {
                        const items = section.items.filter((item: any) => {
                            if (statusTypeObj.statusTypeList.length == 0) {
                                return true
                            } else {
                                return statusTypeObj.statusTypeList.includes(item.status)
                            }
                        })
                        const newSection = {
                            ...section,
                            items
                        }
                        sectionList.push(newSection)
                    })
                    conversationObj.sectionList = sectionList
                }
                conversationObj.sectionList.forEach((section: any) => {
                    section.items.forEach((item: any) => {
                        if (item.message_entity.entity_type == 'video' || item.message_entity.entity_type == 'image') {
                            const options = item?.message_entity.options.filter((option: any) => item.message_entity.link == option.link)
                            if (item.message_entity.link != '' && item.message_entity.link != null && options.length == 0) {
                                item?.message_entity.options.unshift({
                                    created_at: item.message_entity.created_at,
                                    deleted_at: null,
                                    id: null,
                                    is_show: true,
                                    is_system: false,
                                    link: item.message_entity.link,
                                    message_entity_id: item.message_entity.id,
                                    option_type: "",
                                    sequence: 0,
                                    text: item.message_entity.entity_type == 'video' ? `${root.$i18n.t('project.video')}1` : `${root.$i18n.t('project.image')}1`,
                                    updated_at: null,
                                })

                            }
                        }
                    })
                })
            }
        })


        const projectWordCloudDialogRef: Ref<any> = ref(null)
        const projectAIAnalyzeDialogRef: Ref<any> = ref(null)
        const wordCloudInfo = reactive({
            onOpen: (item: any) => {
                const message = liveObj.conversationList.find((v: any) => v.message.message_entity_id == item.message_entity_id)
                if (message && message?.word_cloud.loading) {
                    root.$message.warning(root.$t('projectSurvey.wordCloudDialog.loadingTip').toString())
                    return
                }
                editSessionObj.itemInfo = item
                projectWordCloudDialogRef.value.onOpen(item)
            }
        })

        const aiAnalyseInfo = reactive({
            onOpen: (item: any) => {
                editSessionObj.itemInfo = item
                projectAIAnalyzeDialogRef.value.onOpen(item)
            }
        })



        const projectItemTestGroupsRef: Ref<any> = ref(null)
        const itemTestGroups = reactive({
            onOpen: (item: any) => {
                sectionObj.onTestGroups()
                projectItemTestGroupsRef.value.onOpen(item)
            },
            onSuccess: async (itemObj: any) => {
                await getConversations()
                // busEmit(UPDATE_SESSION_MESSAGE_ENTITY_SUCCESS, itemObj)
                // updateBranch(itemObj.message_entity.id)
            }
        })



        watch(
            () => statusTypeObj.statusTypeList,
            () => statusTypeObj.refreshConversationList()
        )

        const segments: Ref<any[]> = toRef(projectStore.segment, projectUuId.value)
        projectStore.getProjectSegments(projectUuId.value)
        provide('segments', segments)

        watch(
            () => projectInfo.value.online_number,
            (newVal) => {
                if (!projectTalkLoading.value) {

                    liveObj.countTo.change(newVal)
                }
                console.log(`人数更新：${newVal}`)
            }
        )

        watch(
            () => projectInfo.value.status,
            (newVal, oldVal) => {
                console.log(`项目状态更新：${newVal},oldVal:${oldVal}`)
                if (oldVal != undefined && newVal) {
                    initData()
                }
            }
        )


        const liveObj: any = reactive({
            timeInfo: {
                id: computed(() => projectInfo.value.last_conversation_message?.id ?? 0),
                message_entity_id: computed(() => liveObj.lastMessageEntity?.message_entity_id ?? projectInfo.value.last_conversation_message?.message_entity_id ?? 0),
                timeRemain: computed(() => {
                  
                        let remain = 0
                        if (liveObj.lastMessageEntity?.message_reply_expired_at) {

                            const expired_at = new Date(liveObj.lastMessageEntity.message_reply_expired_at).getTime()
                            remain = (expired_at - liveObj.now) / 1000
                            if (remain < 0) {
                                return 0
                            }
                        }
                        if (projectInfo.value?.time_remain && projectInfo.value?.time_remain > 0) {

                            remain = projectInfo.value.time_remain
                        }

                        return remain
                }),
                isCollecting: computed(() => liveObj.timeInfo.timeRemain > 0),
                duration: 0
            },
            pollList: [] as any[],
            conversationList: [] as any[],
            msgCount: computed(() => {
                return liveObj.pollList.length + liveObj.conversationList.length
            }),
            countTo: {
                start: 0,
                end: 0,
                change: (count: number) => {
                    liveObj.countTo.start = liveObj.countTo.end
                    liveObj.countTo.end = count
                }
            },
            participants: computed(() => {
                return projectInfo.value.online_number
            }),
            lastMessageEntity: computed(() => {
                if (liveObj.conversationList.length == 0) {
                    return null
                } else {
                    return liveObj.conversationList[liveObj.conversationList.length - 1].message
                }
            }),
            now: Date.now()
        })
        provide('liveObj', liveObj)


        watch(
            () => liveObj.conversationList.length,
            () => {

                console.log(`刷新会话列表`)
                nextTick(() => setTimeout(() => scrollTalkInfo.scrollBottom(), 1500))
                getConversations()
            }
        )


        const getLiveConversationSingleMessage = async (messageId: string) => {
            if (!projectUuId.value) {
                return
            }
            try {
                const conversationMessageRes = await getConversationSingleMessagesApi({
                    uuid: projectUuId.value,
                    driver: projectInfo.value.dataDriver || '',
                    messageId
                })

                if (conversationMessageRes && conversationMessageRes.length > 0) {
                    const conversationMessage = conversationMessageRes[0]
                    const index = liveObj.conversationList.findIndex((item: any) => item.message.id == conversationMessage.message.id)
                    if (index > -1) {
                        liveObj.conversationList.splice(index, 1, conversationMessage)
                    }
                }
            } catch (err) {
                console.log(err)
            }
        }

        const liveConversationsTotal:any=ref(0)
        const getLiveConversations = async (bottom?: boolean,frist?: boolean) => {
            if (!projectUuId.value) {
                return
            }
            try {
                let uuid = projectUuId.value
                let params={
                    uuid: uuid,
                    driver: projectInfo.value.dataDriver || '',
                }
                // if(frist){
                //     params.limit = 10;
                // }
                let message=await getConversationMessagesApi(params)
                let conversationList = []
                if(frist){
                    conversationList=message.items
                    liveConversationsTotal.value=message.total
                }else{
                    conversationList=message
                    liveConversationsTotal.value=conversationList?conversationList.length:0
                }
                const respondentsList = await getConversationRespondentsApi({
                    uuid: uuid,
                })

                const plist: any[] = []
                const clist: any[] = []
                if (conversationList) {
                    conversationList.forEach((item: any) => {
                        if (item.message.message_stage == 'poll') {
                            item.respondents = respondentsList
                            plist.push(item)
                        }
                        if (item.message.message_stage == 'discussion') {
                            const storedItem = localStorage.getItem(`updateOrCreateConversationMessage_${item.message.message_entity_id}`);
                            item.respondents = respondentsList
                            if (storedItem) {
                                const { message_entity, id } = JSON.parse(storedItem)
                                item.message.ai_settings = message_entity.ai_settings
                                item.message.nlp_settings = message_entity.nlp_settings
                                clist.push(item)
                                localStorage.removeItem(`updateOrCreateConversationMessage_${item.message.message_entity_id}`);
                            } else {
                                clist.push(item)
                            }

                        }
                    })
                }
                liveObj.pollList = plist
                if (liveObj.pollList) {
                    liveObj.pollList.sort((a: any, b: any) => {
                        return a.message.id - b.message.id
                    })
                }

                if (clist.length >= liveObj.conversationList.length) {
                    liveObj.conversationList = clist
                    liveObj.conversationList.sort((a: any, b: any) => {
                        return a.message.id - b.message.id
                    })

                    liveObj.conversationList.forEach((item: any) => {
                        if (item.message.message_type == 'video' || item.message.message_type == 'image') {
                            if (item.message.message_link != '' && item.message.message_link != null) {
                                item?.message.message_options.unshift({
                                    created_at: item.message.created_at,
                                    deleted_at: null,
                                    id: null,
                                    is_show: true,
                                    is_system: false,
                                    link: item.message.message_link,
                                    message_entity_id: item.message.id,
                                    option_type: "",
                                    sequence: 0,
                                    text: item.message.message_type == 'video' ? `${root.$i18n.t('project.video')}1` : `${root.$i18n.t('project.image')}1`,
                                    updated_at: null,
                                })
                            }
                        }
                    })
                    // console.log(liveObj.conversationList,"liveObj.conversationList")
                }
                if (bottom) {
                    nextTick(() => setTimeout(() => scrollTalkInfo.scrollBottom()))
                }
                liveObj.countTo.change(liveObj.participants)
            } catch (error) {
                console.log(error,'reeeee')
                showError(error)
            }
        }

        const detailObj: any = reactive({})
        provide('detailObj', detailObj)

        const getProject = async () => {
            try {
                const { details, owner } = await getProjectApi({
                    uuid: projectUuId.value
                })
                Object.keys(details).forEach((key) => {
                    set(detailObj, key, details[key])
                })
            } catch (error) {
                showError(error)
            }
        }
        getProject()

        const participantInfo = reactive({
            isShow: false
        })


        const sendObj = reactive({
            sendingId: 0,
            lastItem: computed(() => {
                let lastItem
                if (liveObj.conversationList?.length > 0) {
                    const last = liveObj.conversationList[liveObj.conversationList.length - 1]
                    conversationObj.sections.forEach((section: any) => {
                        section.items.forEach((item: any) => {
                            if (item.message_entity.id == last.message.message_entity_id) {
                                lastItem = item
                            }
                        })
                    })
                } else {
                    const last = conversationObj.sections[conversationObj.sections.length - 1]
                    lastItem = last.items[last.items.length - 1]
                }
                return lastItem
            }),
            typeInfo: {
                type: EntityType.speak,
                change: (val: EntityType, retainContent: boolean = true) => {
                    sendObj.initItemObj(retainContent)
                    sendObj.typeInfo.type = val
                    if ([EntityType.poll, EntityType.poll_multi, EntityType.rank].includes(val)) {
                        sendObj.itemObj.message_entity.options = [{ text: '' }] as any
                    }
                    if ([EntityType.speak, EntityType.image, EntityType.video].includes(val)) {
                        sendObj.itemObj.message_entity.duration=10
                    }
                }
            },
            itemObj: {
                message_entity: _.cloneDeep(handleNlpSettings(conversationObj.sections))
            } as any,
            initItemObj: (retainContent: boolean = true) => {
                const entityTypeTemplateCopy = _.cloneDeep(handleNlpSettings(conversationObj.sections))
                Object.keys(entityTypeTemplateCopy).forEach((key: any) => {
                    if (key == 'settings') {
                        if (sendObj.itemObj.message_entity[key].length > 0) {
                            sendObj.itemObj.message_entity[key][0].options = {
                                none: false,
                                other: false,
                                randomized: false
                            }
                            sendObj.itemObj.message_entity[key][0].ai_enable = true
                            sendObj.itemObj.message_entity[key][0].recommend_enable = true
                        } else {
                            sendObj.itemObj.message_entity[key].push({
                                ai_enable: true,
                                options: {
                                    randomized: false,
                                    other: false,
                                    none: false
                                },
                                recommend_enable: true,
                            })
                        }
                    } else if (key == 'content' && retainContent) {

                    } else {
                        sendObj.itemObj.message_entity[key] = entityTypeTemplateCopy[key]
                    }
                })
            },
            isCollapse: true
        })
        provide('sendObj', sendObj)

        const formBasicDialogWrapRef: Ref<any> = ref()
        const editBasicObj = reactive({
            itemInfo: {} as any,
            onOpen: (item: any, type: string) => {
                editBasicObj.itemInfo = item
                formBasicDialogWrapRef.value.initFun(JSON.parse(JSON.stringify(item)), type)
            },
            onSuccess: async (type: string, data: any) => {
                console.log(type, data)
                const itemsIndex = baseObj.polls.findIndex((v: any) => v.id === editBasicObj.itemInfo.id)
                console.log(itemsIndex)

                if (type === 'create') {
                    baseObj.polls.insert(itemsIndex + 1, data)
                    console.log(baseObj.polls)

                    await baseObj.onMove()
                } else if (type === 'edit') {
                    set(baseObj.polls, itemsIndex, data)
                }
            }
        })
        const formSessionDialogWrapRef: Ref<any> = ref()
        const editSessionObj = reactive({
            itemInfo: {} as any,
            onOpen: (item: any, type: string, entityType?: EntityType) => {
                editSessionObj.itemInfo = item
                if (entityType == EntityType.branch) {
                    formSessionDialogWrapRef.value.initBranchFun(JSON.parse(JSON.stringify(item)), type)
                } else {
                    formSessionDialogWrapRef.value.initFun(JSON.parse(JSON.stringify(item)), type)
                }
            },
            onSuccess: async (type: string, data: any) => {
                let section
                let itemsIndex = 0
                if ([EntityType.onboarding_poll, EntityType.onboarding_poll_picture].includes(editSessionObj.itemInfo.message_entity.entity_type)) {

                    section = conversationObj.sections[conversationObj.sections.length - 1]
                    itemsIndex = section.items.length - 1
                } else {
                    section = conversationObj.sections.find((section: any) => {
                        return section.id == editSessionObj.itemInfo.section_id
                    })
                    itemsIndex = section.items.findIndex((v: any) => v.id === editSessionObj.itemInfo.id)
                }

                if (type === 'create') {
                    section.items.insert(itemsIndex + 1, data)
                    await messageObj.onMove(section)
                } else if (type === 'edit') {
                    set(section.items, itemsIndex, data)
                    if (checkWordCloudApplyGlobal(data)) {

                        let refreshList = data.message_entity.nlp_settings.apply_global_color || data.message_entity.nlp_settings.apply_global_shape
                        if (refreshList) {
                            setTimeout(() => {
                                getLiveConversations()
                            }, 1000)
                        } else if (data.status === ConversationStatusType.SENDED) {
                            const item = liveObj.conversationList.find((v: any) => v.message.message_entity_id === data.message_entity_id)
                            if (item?.word_cloud) {
                                set(item.word_cloud?.nlp_settings, 'keywords_enable', data.message_entity.nlp_settings.keywords_enable)
                                set(item.word_cloud?.nlp_settings, 'nlp_enable', data.message_entity.nlp_settings.nlp_enable)
                                set(item.word_cloud?.nlp_settings, 'shape', data.message_entity.nlp_settings.shape)
                                set(item.word_cloud?.nlp_settings, 'color', data.message_entity.nlp_settings.color)
                            }
                            getLiveConversationSingleMessage(item.message.message_identifier)
                        }
                    }
                }
                statusTypeObj.refreshConversationList()
            }
        })


        const checkWordCloudApplyGlobal = (data: any) => {
            return [EntityType.ask_opinion, EntityType.branch].includes(data.message_entity.entity_type) && data.message_entity.nlp_settings
        }

        const projectTalkLoading = ref(true)
        const initData = async () => {
            await projectStore.getProjectInfo(projectUuId.value)

            const promiseList = [getOnboardingPolls(), getConversations()]


            if ([ProjectStatus.Published, ProjectStatus.Start].includes(projectInfo.value.status)) {
                promiseList.push(getLiveConversations(true))
                timerStart('conversions_refresh', getLiveConversations, 5000)
            } else if (projectInfo.value.status === ProjectStatus.End) {
                // 1.0
                // promiseList.push(getLiveConversations(true))
                // timerClear('conversions_refresh')
                // 项目结束后需要刷新 2.0 
                promiseList.push(getLiveConversations(true))
                timerStart('conversions_refresh', getLiveConversations, 5000)
            }

            Promise.all(promiseList).then(() => {
                projectTalkLoading.value = false
            })
        }


        const wsInfo = {

            gatewayURL: `${location.protocol === 'https:' ? 'wss:' : 'ws:'}//${location.host}/gateway`,
            authInfo: {
                token: userStore.userInfo.token ?? '',
                projectUuid: projectUuId.value,
                guard: LoginGuard.Moderator
            },
            cli: {} as ARTClient,
            saveToSendMessage: async (item: any) => {

                try {
                    // console.log(`saveToSendMessage: ${JSON.stringify(item)}`)
                    const res = await updateOrCreateConversationMessageApi(item)
                    if (res.id) {

                        const section = conversationObj.sections.find((section: any) => {
                            return section.id == item.section_id
                        })

                        const itemIndex = section.items.findIndex((it: any) => {
                            return it.sequence == item.sequence
                        })

                        if (itemIndex > -1) {
                            section.items.splice(itemIndex, 0, res)
                        } else {
                            section.items.push(res)
                        }

                        sendObj.typeInfo.change(EntityType.speak, false)

                        await messageObj.onMove(section)

                        wsInfo.sendMessage(res)
                    }
                } catch (error) {
                    showError(error)
                }
            },
            sendMessage: async (item: any) => {
                if (item.message_entity.test_group_id != 0) {
                    if (item.message_entity.test_group.message_entities.length == 0) {
                        root.$message.warning(root.$t('projectTestGroup.testGroupNoneMsg').toString())
                        return
                    }
                }
                sendObj.sendingId = item.message_entity_id
                const conversation = new ConversationBody(wsInfo.authInfo.projectUuid)
                conversation.sequence = liveObj.msgCount
                conversation.messageEntityId = String(item.message_entity_id)
                conversation.messageStage = 'discussion'
                conversation.messageReplyQuantity = 1
                conversation.messageType = item.message_entity.entity_type
                conversation.messageDuration = item.message_entity.duration

                if (conversation.messageType) {

                    const pollList = [EntityType.poll, EntityType.poll_multi, EntityType.picture_poll, EntityType.picture_poll_multi, EntityType.rank, EntityType.ask_experience]
                    const mediaList = [EntityType.image, EntityType.video]
                    if (pollList.includes(item.message_entity.entity_type)) {

                        conversation.messageOptions = JSON.stringify(item.message_entity.options)
                    } else if (mediaList.includes(item.message_entity.entity_type)) {
                        conversation.messageLink = item.message_entity.link

                        conversation.messageOptions = JSON.stringify(item.message_entity.options)
                    }
                }

                conversation.messageContent = item.message_entity.content


                const conversationBody = new SendMessageBody(conversation)
                const { status, resp, err } = await wsInfo.cli.talk(conversationBody)
                // console.log(`webSocket登录状态: ${status},resp: ${JSON.stringify(resp)},err: ${JSON.stringify(err)}`)

                if (status == 0) {
                    item.status = ConversationStatusType.SENDED
                    const message = {
                        message: {
                            id: 0,
                            project_uuid: conversation.projectUuid,
                            sequence: conversation.sequence,
                            message_entity_id: item.message_entity_id,
                            message_stage: conversation.messageStage,
                            message_reply_expired_at: Date.now() + projectInfo.value.server_offset + item.message_entity.duration * 1000,
                            message_type: conversation.messageType,
                            message_duration: item.message_entity.duration,
                            message_content: conversation.messageContent,
                            message_link: conversation.messageLink,
                            message_options: conversation.messageOptions,
                            branchs: item.message_entity?.branchs ?? [],
                            settings: item.message_entity?.settings ?? []
                        },
                        collection: null
                    }
                    liveObj.conversationList.push(message)
                    setTimeout(async () => {
                        getConversations()
                        statusTypeObj.refreshConversationList()
                        getLiveConversations()
                    }, 1000)

                }
                sendObj.sendingId = 0
            },
            stopTestGroup: async (item: any) => {
                let message = <string>root.$i18n.t('projectTestGroup.skipTestGroupMsg')
                root.$msgbox({
                    showCancelButton: true,
                    dangerouslyUseHTMLString: true,
                    message,
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            try {
                                await stopCurrentTestGroupApi({
                                    uuid: projectUuId.value,
                                    testGroupId: item.message_entity.test_group_id,
                                })
                                getConversations()
                                done()
                            } catch (error) {
                                showError(error)
                            }
                        } else {
                            done()
                        }
                    }
                }).catch(() => { })

            },
            initWsSdk: async () => {

                wsInfo.cli = new ARTClient(wsInfo.gatewayURL)

                const eventcallback = (evt: ARTEvent, data?: any) => {
                    if (evt == ARTEvent.Notify) {
                        console.log(`通知事件【${evt}】，执行对应执行参数:${JSON.stringify(data)}`)
                        if (data.event == ARTNotifyEventType.QuestionUpdate) {
                            _.debounce(() => {
                                console.log(`更新题目`)
                                getOnboardingPolls()
                                getConversations()
                            }, 1500)()
                        } else if (data.event == ARTNotifyEventType.SegmentUpdate) {
                            _.debounce(() => {
                                console.log(`更新分群`)
                                projectStore.updateProjectSegments(data.projectUUID)
                            }, 1500)()
                        } else if (data.event == ARTNotifyEventType.NLPCompleteComputing) {
                            const messageId = data.messageID
                            console.log(`更新词云计算:${messageId}`)
                            _.debounce(async () => {
                                await getLiveConversationSingleMessage(messageId)
                            }, 1500)()
                        }
                    }
                }

                const messagecallback = (m: MessageBody) => { }

                const offmessagecallback = (oms: MessageBody[]) => {
                    oms.forEach((om) => { })
                }

                const evts = [ARTEvent.Closed, ARTEvent.Reconnecting, ARTEvent.Reconnected, ARTEvent.Kickout, ARTEvent.Notify]

                wsInfo.cli.register(evts, eventcallback)

                wsInfo.cli.onmessage(messagecallback)

                wsInfo.cli.onofflinemessage(offmessagecallback)


                const { success, err } = await wsInfo.cli.login(wsInfo.authInfo)
                console.log(`login ${success}`)

                if (!success) {
                    console.log(err)
                }
            },
            clear: async () => {
                if (wsInfo.cli) {
                    console.log(`wsInfo cli logout`)
                    await wsInfo.cli.logout()
                    wsInfo.cli = {} as ARTClient
                }
            }
        }

        onMounted(() => {
            console.log(`ProjectLive onMounted`)
            wsInfo.initWsSdk()
            initData()
            timerStart(
                'time_refresh',
                () => {
                    liveObj.now = Date.now() + projectInfo.value.server_offset ?? 0
                }, 1000)
        })

        onUnmounted(() => {
            console.log(`ProjectLive onUnmounted`)
            wsInfo.clear()
        })

        const isLeave = ref(false)
        onBeforeUnmount(() => {
            isLeave.value = true
        })

        const documentTitle = computed(() => {
            if (calculateTimeInfo.countdown > 0) {
                return `(${timeFormat(calculateTimeInfo.countdown, 'mm:ss')})${liveObj.lastMessageEntity ? liveObj.lastMessageEntity.message_content.replace(/<[^>]+>/g, '') : projectInfo.value.detail_title
                    }`
            } else {
                return projectInfo.value.detail_title
            }


        })

        watch(
            () => liveObj.timeInfo.timeRemain,
            () => {
                if (liveObj.timeInfo.timeRemain > 0 && !calculateTimeInfo.isRun) {
                    calculateTimeInfo.start()
                }
            }
        )

        const calculateTimeInfo = reactive({
            isRun: false,
            countdown: 0,
            currentInterval: null as any,
            start: () => {
                calculateTimeInfo.clear()
                const time = liveObj.timeInfo.timeRemain * 1000 + new Date().getTime()
                calculateTimeInfo.currentInterval = workerTimer.setInterval(() => {
                    calculateTimeInfo.isRun = true
                    calculateTimeInfo.countdown = time - new Date().getTime()
                    if (calculateTimeInfo.countdown <= 0) {
                        calculateTimeInfo.clear()
                    }

                }, 1000)
            },
            clear: () => {
                if (calculateTimeInfo.currentInterval) {

                    workerTimer.clearInterval(calculateTimeInfo.currentInterval)
                    calculateTimeInfo.currentInterval = null
                    calculateTimeInfo.isRun = false
                    calculateTimeInfo.countdown = 0
                }
                getConversations()
            }
        })


        const onShowWordCloudDialog = (message_entity_id: number) => {
            let section = null
            for (let index = 0; index < conversationObj.sections.length; index++) {
                const sectionObj = conversationObj.sections[index]
                section = sectionObj.items.find((v: any) => v.message_entity_id == message_entity_id)
                if (section) {
                    break
                }
            }
            if (section) {
                wordCloudInfo.onOpen(section)
            }
        }

        provide('onShowWordCloudDialog', onShowWordCloudDialog)

        const onShowAIAnalyzeDialog = (message_entity_id: number) => {
            let section = null
            for (let index = 0; index < conversationObj.sections.length; index++) {
                const sectionObj = conversationObj.sections[index]
                section = sectionObj.items.find((v: any) => v.message_entity_id == message_entity_id)
                if (section) {
                    break
                }
            }
            if (section) {
                aiAnalyseInfo.onOpen(section)
            }
        }

        provide('onShowAIAnalyzeDialog', onShowAIAnalyzeDialog)


        return {
            scrollFormRef,
            scrollFormInfo,
            projectStatusInfo,
            projectTalkLoading,
            ProjectFormType,
            ConversationStatusType,
            ProjectStatus,
            projectUuId,
            projectInfo,
            baseObj,
            outlineObj,
            conversationObj,
            sendObj,
            wsInfo,
            scrollTalkRef,
            scrollTalkInfo,
            participantInfo,
            liveObj,
            statusTypeObj,
            EntityType,
            sectionObj,
            messageObj,
            projectSectionSortRef,
            sectionSortInfo,
            projectItemMoveRef,
            itemMoveInfo,
            formBasicDialogWrapRef,
            editBasicObj,
            formSessionDialogWrapRef,
            editSessionObj,
            changeSectionMessageStatusFun,
            talkCreateBranch,
            dataDeriveInfo,
            documentTitle,
            handleProjectTotalTime,
            projectWordCloudDialogRef,
            projectAIAnalyzeDialogRef,
            wordCloudInfo,
            aiAnalyseInfo,
            projectItemTestGroupsRef,
            itemTestGroups,
            isLeave,
            ProjectLiveTalk,
            formattedConversationList,
            liveConversationsTotal
        }
    }
})

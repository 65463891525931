



































































import { defineComponent, inject, computed, ref } from '@vue/composition-api'

import { ConversationStatusType, EntityType, ProjectFormType } from '@/api/project/model'
import { allEntityTypeItems } from '@/api/project/entity-type-option'
import VideoDialog from '@/components/VideoDialog.vue'
import TestGroupStatus from './components/TestGroupStatus.vue'
import { timeFormatLocaleStr } from '@/utils/time-format'
export default defineComponent({
    components: {
        VideoDialog,
        TestGroupStatus
    },
    setup(props, { root, emit }) {
        const itemInfo: any = inject('itemInfo')
        const timeTota = computed(() => {
            console.log(itemInfo.message_entity, 'ssss')
            if (itemInfo.message_entity.test_group_id != 0) {
                if (itemInfo.message_entity.options.length > 0) {
                    let time = 0
                    const maxItem = itemInfo.message_entity.options.reduce((max:any, item: any) => {
                        return (item.duration > max.value) ? item : max;
                    }, itemInfo.message_entity.options[0]); 
                    
                    return timeFormatLocaleStr(maxItem.duration)
                } else {
                    return null
                }
            } else {
                if (itemInfo.message_entity.options.length > 0) {
                    let time = 0
                    itemInfo.message_entity.options.map((v: any) => {
                        time += v.duration
                    })
                    return timeFormatLocaleStr(time)
                } else {
                    return null
                }
            }

        })
        const messageInfo = computed(() => {
            const { entity_type } = itemInfo.message_entity
            const entityTypeOption = allEntityTypeItems.find((v) => v.value == entity_type)
            return {
                name: entityTypeOption?.label,
                icon: entityTypeOption?.icon
            }
        })
        const videoDialogRef = ref()
        const showVideoDialog = (url: string) => {
            if (url) {
                videoDialogRef.value.open(url)
            }
        }

        return {
            ConversationStatusType,
            EntityType,
            ProjectFormType,
            itemInfo,
            messageInfo,
            videoDialogRef,
            showVideoDialog,
            timeTota
        }
    }
})
